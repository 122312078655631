<template>
    <v-autocomplete
      v-model="value"
      cache-items
      dense
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      hide-selected
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="xproveedor_id"
      item-text="xnomabrev"
      outlined
      no-data-text="Introduzca texto a buscar"
      :label="label"
      :readonly="readonly"
      return-object
      ref="FindCtrl"
      :clearable="!readonly"
      :multiple="multiple"
      :menu-props="{'open-on-click': true}"
      @click="clickEvt"
      @click:append="clickAppendEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
      @update:search-input="updateSearchInputEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: {
      value: { default: null },
      label: { type: String, default: '' },
      multiple: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
      smallChips: { type: Boolean, default: false },
      deletableChips: { type: Boolean, default: false },
      selectOnReadonly: { type: Boolean, default: false },
      fecha: { type: String, default: null },
      dirId: { type: Number, default: null }
    },
    watch: {
      searchItem(val) {
       this.loadItems(val);
      },
      dirId() {
        this.$refs.FindCtrl.cachedItems = [];
        this.loadItems();
      },
      fecha() {
        this.$refs.FindCtrl.cachedItems = [];
        this.loadItems();
      },
      value() {
        if (this.value != null && this.value != undefined && this.value != '') {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {
            Search: valFind,
            EmpId: this.empId,
            DirId: this.dirId,
            FechaPedido: this.fecha,
            NumRegsPag: 60,
            NumPag: 1
        };

      var valorActual = this.value;
      var AuthToken = localStorage.getItem('token'); 
       axios({ method: "POST", "url": this.urlRaiz + "/api/artprov/provs/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
       .then(result => {
            this.itemsList = result.data.EntsList;

            if(valorActual != null) { 
              if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.xproveedor_id != null) {
                  if (!this.itemsList.some(itm=>itm.xproveedor_id === valorActual.xproveedor_id)) {
                    this.itemsList.push(valorActual);
                  }
                }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.xproveedor_id === element.xproveedor_id)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.FindCtrl.isMenuActive) {
            this.$refs.FindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.FindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.FindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      },
      updateSearchInputEvt() {
        this.$emit('update:search-input', this.value)
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems();
    }
  }
</script>