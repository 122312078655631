<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
              <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                <span v-if="isNew">Nuevo</span>
                <span v-if="!isNew">Editar</span>
                pedido
              </div>

              <!-- FORMULARIO PEDIDO -->
              <v-row class="my-2 mx-2">
                <v-col class="mb-n7" cols="6" sm="4" md="3" lg="3" xl="2">
                    <DateFind :readonly="enviado" label="Fecha" v-model="pedido.FechaPedido"></DateFind>
                </v-col>

                <v-col v-if="pedido.FechaPedido != null && pedido.FechaPedido != ''" cols="11" sm="7" md="6" lg="5" xl="4">
                    <ProovedoresArtProvFind class="mb-n7" ref="provCtrl" 
                        v-model="pedido.ProveedorObj" label="Proveedor"
                        :dirId="this.dirId"
                        :readonly="!isNew || editNewRow || enviado || lineasDataRows.length > 0"
                        :fecha="!filtroDia ? pedido.FechaPedido : null"
                        @change="changeProveedor">
                    </ProovedoresArtProvFind>
                </v-col>
                <v-col class="px-0 pt-4 mr-n15" v-if="pedido.FechaPedido != null && pedido.FechaPedido != ''" cols="1" sm="1" md="1" lg="1" xl="1">
                    <v-simple-checkbox v-model="filtroDia" :ripple="false"></v-simple-checkbox> 
                </v-col>

                <v-col v-if="pedido.ProveedorObj != null && pedido.ProveedorObj.FechaEntregaVisible" class="mb-n7" cols="6" sm="4" md="3" lg="3" xl="2">
                    <DateFind :readonly="enviado" label="Fecha de entrega" v-model="pedido.FechaEntrega"></DateFind>
                </v-col>
                <v-col v-if="!isMartin" cols="12" sm="6" md="3" lg="1" xl="1">
                  <v-text-field
                      class="mb-n7 no-arrows" outlined dense
                      type="number" min="0"
                      :readonly="enviado"
                      v-model="pedido.dias_festivos"
                      label="Festivos"
                      @focus="$event.target.select()"
                  ></v-text-field>
              </v-col>
              <v-col v-if="!isMartin" cols="12" sm="6" md="3" lg="1" xl="1">
                  <v-text-field
                      class="mb-n7 no-arrows" outlined dense
                      type="number" min="0"
                      :readonly="enviado"
                      v-model="pedido.dias_post_calculo"
                      label="Días consumo"
                      @focus="$event.target.select()"
                  ></v-text-field>
              </v-col>
              <v-col v-if="!isMartin" cols="12" sm="6" md="3" lg="1" xl="1">
                  <v-text-field
                      class="mb-n7 no-arrows" outlined dense
                      type="number" min="0"
                      :readonly="enviado"
                      v-model="pedido.plazo_entrega"
                      label="Entrega"
                      @focus="$event.target.select()"
                  ></v-text-field>
              </v-col>
              </v-row>
              <v-row class="my-2 mx-2">
                
              </v-row>

              <v-expansion-panels v-model="activePanels" class="px-4">
                  <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                      <v-expansion-panel-header>
                          <span class="header">Filtros</span>
                          <template v-slot:actions>
                              <v-btn icon @click.stop="toogleFilters">
                                  <v-icon>mdi-menu-down</v-icon>
                              </v-btn>
                          </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                          <v-row>
                              <v-col v-if="pedido.PedidoId != null" class="py-0" cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-btn
                                    :loading="loading"
                                    :disabled="enviado || pedido.ProveedorObj == null"
                                    @click="onBtnFinalizarFamilia"
                                    color="primary"
                                    small
                                    class="mb-2 mr-2">
                                    Finalizar familia
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="7" md="5" lg="5" xl="4">
                                  <FamiliaArtProvFind class="mb-n7" ref="familiaCtrl" 
                                      v-model="filtros.Familia" label="Familia"
                                      :dirId="this.dirId"
                                      :proveedor="pedido.ProveedorObj != null ? pedido.ProveedorObj.xproveedor_id : null"
                                      :fecha="!filtroDia ? pedido.FechaPedido : null" :familiasExcluidas="pedido.FamiliasFinalizadasList"
                                      @change="filtros.Subfamilia = null">
                                  </FamiliaArtProvFind>
                              </v-col>
                              <v-col cols="12" sm="7" md="5" lg="5" xl="4">
                                  <SubfamiliaArtProvFind class="mb-n7" ref="subfamiliaCtrl" 
                                      v-model="filtros.Subfamilia" label="Subfamilia"
                                      :dirId="this.dirId" :familia="filtros.Familia != null ? filtros.Familia.familia : null"
                                      :proveedor="pedido.ProveedorObj != null ? pedido.ProveedorObj.xproveedor_id : null"
                                      :fecha="!filtroDia ? pedido.FechaPedido : null"
                                      :readonly="filtros.Familia == null">
                                  </SubfamiliaArtProvFind>
                              </v-col>
                            </v-row>
                      </v-expansion-panel-content>
                  </v-expansion-panel>
              </v-expansion-panels>
              
              <v-row class="mt-1">
                <!-- LINEAS -->
                <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                  
                  <v-row no-gutters>
                    <!-- Botón añadir linea -->
                    <v-btn v-if="!isMartin"
                        :loading="loading"
                        :disabled="editNewRow || enviado || pedido.ProveedorObj == null"
                        @click="onBtnNuevaLinea"
                        color="primary"
                        small
                        class="mb-2 mr-2">
                        Nueva línea
                    </v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="editNewRow || enviado || pedido.ProveedorObj == null"
                        @click="onBtnAllArt"
                        color="primary"
                        small
                        class="mb-2">
                        Añadir todos los articulos
                    </v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="editNewRow || enviado || pedido.ProveedorObj == null"
                        @click="onBtnAddArts"
                        color="primary"
                        small
                        class="mb-2">
                        Añadir articulos
                    </v-btn>

                    <!-- SIN TOCAR -->

                    <v-btn @click="showArtsDialog = true">Añadir Artículos</v-btn>
            <v-dialog
                v-model="showArtsDialog"
                transition="dialog-bottom-transition"
                persistent eager
                max-width="90%"
            >
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        INTRODUCIR ARTICULOS
                    </v-card-title>

                    <v-card-text class="mt-2">
                        <v-row>
                            <v-col cols="3">
                                <v-list>
                                    <v-list-group
                                        v-for="family in familias"
                                        :key="family.name"
                                        v-model="filtros.Familia"
                                        :prepend-icon="family.active ? 'mdi-minus' : 'mdi-plus'"
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ family.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <!--
                                        <v-list-item
                                            v-for="subfamily in family.subfamilies"
                                            :key="subfamily.name"
                                            @click="selectSubfamily(subfamily)"
                                            :active="selectedSubfamily && selectedSubfamily.name === subfamily.name"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>{{ subfamily.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                      -->
                                    </v-list-group>
                                    
                                </v-list>
                            </v-col>
                            <v-col cols="9">
                              <!--
                                <v-text-field
                                    v-model="searchQuery"
                                    label="Buscar por código de artículo"
                                    class="mb-4"
                                ></v-text-field>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Código</th>
                                            <th>Cantidad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="article in filteredArticles" :key="article.code">
                                            <td>{{ article.name }}</td>
                                            <td>{{ article.code }}</td>
                                            <td>
                                                <v-text-field
                                                    v-model.number="article.quantity"
                                                    type="number"
                                                    min="0"
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                -->
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="onBtnCloseArts">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>








                  <!--SIN TOCAR-->
                    <v-spacer></v-spacer>
                    
                    <v-switch class="mt-0 pt-0 pb-1 mr-2"
                      v-model="filtrarFamilias"
                      :label="'Filtrar familias finalizadas'"
                      :hide-details="true"
                      @change="loadLineData"
                    ></v-switch>
                    <v-switch class="mt-0 pt-0 pb-1"
                      v-model="filtrarCantidad"
                      :label="'Filtrar cantidad'"
                      :hide-details="true"
                      @change="loadLineData"
                    ></v-switch>
                  </v-row>
                  
                  <!-- :server-items-length="lineasTotalDataRows" -->
                  <v-data-table
                    :headers="tableHeaders"
                    :items="lineasDataRows"
                    :server-items-length="apiLineasTotal"
                    :options.sync="lineasOptions"
                    :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                    :loading="loading"
                    @click:row="onClickRowEditarLinea"
                    style="overflow: scroll"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    dense
                    >
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <!-- <template v-slot:item.Articulo="{item}">
                        <div v-if="item.edit && !enviado && !isMartin" class="my-1">
                          <v-row no-gutters>
                              <v-col>
                                  <ArticuloArtProvFind 
                                      class="mb-n7" v-model="selectedRow.Art" label="Artículo"
                                      :dirId="dirId" 
                                      :proveedor="pedido.ProveedorObj != null ? pedido.ProveedorObj.xproveedor_id : null"
                                      :familia="filtros.Familia != null ? filtros.Familia.familia : null"
                                      :subfamilia="filtros.Subfamilia != null ? filtros.Subfamilia.subfamilia : null"
                                      :fecha="!filtroDia ? pedido.FechaPedido : null" :codigoBarras="filtros.codigoBarras"
                                      :familiasExcluidas="pedido.FamiliasFinalizadasList">
                                  </ArticuloArtProvFind>
                              </v-col>
                              <v-col cols="1">
                                  <v-btn icon text color="primary" @click="onBtnShowProductDialog(selectedRow.Art)"><v-icon>mdi-eye</v-icon></v-btn>
                              </v-col>
                          </v-row>
                        </div>
                        <template v-else>
                          {{item.Articulo}}
                        </template>
                      </template> -->
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.Stock="{item}">
                        <div v-if="item.edit && !enviado" class="my-1">
                          <v-text-field
                            class="mb-n7 no-arrows" outlined dense
                            type="number" min="0"
                            v-model="selectedRow.Stock"
                            label="Stock"
                            @blur="onBlurFixStock()"
                            @focus="$event.target.select()"
                          >
                            <v-icon class="mb-n1" slot="append" @click.stop="changeStock(selectedRow.Stock)">mdi-plus</v-icon>
                            <v-icon class="mb-n1" slot="prepend" @click.stop="changeStock(selectedRow.Stock, -1)">mdi-minus</v-icon>
                          </v-text-field>
                        </div>
                        <template v-else>
                          {{item.Stock.toFixed(2)}}
                        </template>
                      </template>
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.CantidadPropuesta="{item}">
                        <div v-if="item.edit && !enviado" class="my-1">
                        <v-text-field
                          class="mb-n7 no-arrows" outlined dense
                          type="number" min="0"
                          v-model="selectedRow.CantidadPropuesta"
                          label="Sugerencia"
                           @blur="onBlurFixCantidadPropuesta"
                          @focus="$event.target.select()"
                          disabled
                        >
                        </v-text-field>
                      </div>
                      <template v-else>
                        {{item.CantidadPropuesta.toFixed(2)}}

                      </template>
                    </template>
                      
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.Cantidad="{item}">
                        <div v-if="item.edit && !enviado" class="my-1">
                          <v-text-field
                            class="mb-n7 no-arrows" outlined dense
                            type="number" min="0"
                            v-model="selectedRow.Cantidad"
                            label="Cantidad"
                            @blur="onBlurEdit"
                            @focus="$event.target.select()"
                          >
                            <v-icon class="mb-n1" slot="append" @click.stop="changeCantidad(selectedRow.Cantidad)">mdi-plus</v-icon>
                            <v-icon class="mb-n1" slot="prepend" @click.stop="changeCantidad(selectedRow.Cantidad, -1)">mdi-minus</v-icon>
                          </v-text-field>
                        </div>
                        <template v-else>
                          {{item.Cantidad.toFixed(2)}}
                        </template>
                      </template>
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <!-- <template v-slot:item.CodigoBarras="{item}">
                        <div v-if="item.edit && !enviado" style="min-width: 150px">
                            <v-text-field
                                class="mb-n7" outlined dense
                                v-model="item.CodigoBarras"
                                label="Código de barras"
                                @focus="$event.target.select()"
                            ></v-text-field>
                        </div>
                      </template> -->
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.FactorCompra="{item}">
                        <span v-if="item.edit && !enviado"><template v-if="selectedRow.Art!=null">{{selectedRow.Art.FactorCompra}}</template></span>
                        <span v-else>{{getFactor(item.ArticuloId)}}</span>
                      </template>
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-if="isMartin" v-slot:item.LoteMultiplo="{item}">
                        <span v-if="item.edit && !enviado"><template v-if="selectedRow.Art!=null">{{selectedRow.Art.LoteMultiplo}}</template></span>
                        <span v-else>{{getMultiplo(item.ArticuloId)}}</span>
                      </template>
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.Opt="{ item }">
                        <div v-if="item.edit && !enviado" style="min-width: 150px">
                          <v-btn v-if="lineasDataRows.length == 1"
                            :loading="loading"
                            @click.stop="onBtnSaveEditLinea"
                            color="primary"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Aceptar
                            </v-btn>
                           <!-- 
                            <v-btn v-if="!isMartin"
                            :loading="loading"
                            @click="onBtnSugerir"
                            color="warning"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Sugerir
                            </v-btn>
                            -->
                            <v-btn
                            @click.stop="onBtnCancelEditLinea"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Cancelar
                            </v-btn>
                        </div>
                        <template v-else>
                          <v-btn v-if="!enviado && !isMartin"
                            color="error"
                            class="mr-2 my-1"
                            small
                            @click.stop="onBtnShowLineaDeleteModal(item)"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </template>
                      <!-- NUEVA LINEA FORMULARIO -->
                      <template v-if="editNewRow && !enviado" slot="body.append">
                        <tr class="new-row">
                            <td>
                              <v-row no-gutters>
                                  <v-col>
                                      <ArticuloArtProvFind 
                                          class="mb-n7" v-model="newPedidoRow.Art" label="Artículo"
                                          :dirId="dirId"
                                          :proveedor="pedido.ProveedorObj != null ? pedido.ProveedorObj.xproveedor_id : null"
                                          :familia="filtros.Familia != null ? filtros.Familia.familia : null"
                                          :subfamilia="filtros.Subfamilia != null ? filtros.Subfamilia.subfamilia : null"
                                          :fecha="!filtroDia ? pedido.FechaPedido : null" :codigoBarras="filtros.codigoBarras"
                                          :familiasExcluidas="pedido.FamiliasFinalizadasList">
                                      </ArticuloArtProvFind>
                                  </v-col>
                                  <v-col cols="1">
                                      <v-btn icon text color="primary" @click="onBtnShowProductDialog(newPedidoRow.Art)"><v-icon>mdi-eye</v-icon></v-btn>
                                  </v-col>
                              </v-row>
                            </td>
                            <!-- <td style="min-width: 150px">
                                <v-text-field
                                    class="mb-n7" outlined dense
                                    v-model="newPedidoRow.CodigoBarras"
                                    label="Código de barras"
                                    @focus="$event.target.select()"
                                ></v-text-field>
                            </td> -->
                            <td>
                              <v-text-field
                                class="mb-n7 no-arrows" outlined dense
                                type="number" min="0"
                                v-model="newPedidoRow.Stock"
                                label="Stock"
                                @blur="onBlurFixStock"
                                @focus="$event.target.select()"
                              >
                                <v-icon class="mb-n1" slot="append" @click.stop="changeStock(newPedidoRow.Stock,1,true,true)">mdi-plus</v-icon>
                                <v-icon class="mb-n1" slot="prepend" @click.stop="changeStock(newPedidoRow.Stock,-1,true,true)">mdi-minus</v-icon>
                              </v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                class="mb-n7 no-arrows" outlined dense
                                type="number" min="0"
                                v-model="newPedidoRow.CantidadPropuesta"
                                label="Sugerencia"
                                @blur="onBlurFixCantidadPropuesta"
                                @focus="$event.target.select()"
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                class="mb-n7 no-arrows" outlined dense
                                type="number" min="0"
                                v-model="newPedidoRow.Cantidad"
                                label="Cantidad"
                                @blur="onBlurNew"
                                @focus="$event.target.select()"
                              >
                                <v-icon class="mb-n1" slot="append" @click.stop="changeCantidad(newPedidoRow.Cantidad,1,true,true)">mdi-plus</v-icon>
                                <v-icon class="mb-n1" slot="prepend" @click.stop="changeCantidad(newPedidoRow.Cantidad,-1,true,true)">mdi-minus</v-icon>
                              </v-text-field>
                            </td>
                            <td><span v-if="newPedidoRow.Art != null && isMartin">{{newPedidoRow.Art.loteMultiplo}}</span></td>
                            <td style="min-width: 250px"  align ="right">
                              <v-btn
                                :loading="loading"
                                @click="onBtnSaveLinea"
                                color="primary"
                                class="mt-2 mb-4 mr-2"
                                small>
                                Aceptar
                                </v-btn>
                                
                                <v-btn
                                @click="onBtnCancelLinea"
                                class="mt-2 mb-4 mr-2"
                                small>
                                Cancelar
                                </v-btn>
                            </td>
                        </tr>
                      </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                    <v-textarea
                        class="mt-0 mb-n7" outlined auto-grow
                        :readonly="enviado"
                        v-model="pedido.Observaciones"
                        label="Observaciones">
                    </v-textarea>
                </v-col>
              </v-row>

              <!-- ACEPTAR / ENVIAR / CANCELAR / BORRAR -->
              <v-row justify="center">
                  <v-btn v-if="!enviado"
                  :loading="loading"
                  :disabled="!isValid"
                  @click="onBtnSave"
                  color="primary"
                  class="mt-2 mb-4 mr-2"
                  style="width:100px">
                  Aceptar
                  </v-btn>

                  <v-btn v-if="pedido.PedidoId != null && !enviado"
                  :loading="loading"
                  @click="confirmarEnviar"
                  color="primary"
                  class="mt-2 mb-4 mr-2"
                  style="width:100px">
                  Enviar
                  </v-btn>
                
                  <v-btn 
                  class="mt-2 mb-4 mr-2"
                  style="width:100px"
                  @click="goToList">
                    <template v-if="lineasDataRows.length == 0">Cancelar</template>
                    <template v-else>Salir</template>
                  </v-btn>

                  <v-btn v-if="!isNew && !enviado"
                  color="error"
                  class="mt-2 mb-4"
                  style="width:100px"
                  @click="onBtnShowDeleteModal()"
                  >
                  Borrar
                  </v-btn>

                  <!-- <v-btn class="mt-2 mb-4 ml-3"
                  @click="enviado = !enviado"
                  >
                  test readonly
                  </v-btn> -->
              </v-row>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Pedido -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR PEDIDO
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el pedido? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Modal borrar línea -->
        <v-dialog
          v-model="showDeleteLineaDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR LÍNEA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la línea? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnLineaDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelLineaDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Modal error -->
        <v-dialog
          v-model="showErrorDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              ERROR
            </v-card-title>

            <v-card-text class="mt-2">
              {{errorMsg}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="onBtnCloseError"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <!-- Modal Familias no fimalizadas -->
        <v-dialog
        v-model="showModal"
        transition="dialog-bottom-transition"
        persistent
        width="600"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Familias no finalizadas. ¿Confirmar envío?
            </v-card-title>

            <v-card-text class="mt-2">
              
              <ul v-if="familiasNoFinalizadas.length > 0">
                <li v-for="familia in familiasNoFinalizadas" :key="familia">{{ familia }}</li>
              </ul>
              <span v-else>Todas las familias estan finalizadas</span>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnEnviar"
              >
                Enviar
              </v-btn>
              <v-btn
                @click="closeModal"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- VER PRODUCTO -->
        <v-dialog
          v-model="showProductDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              VER PRODUCTO
            </v-card-title>

            <v-card-text class="mt-2">
              Datos del producto:
              <div v-if="selectedProduct != null">
                <ul>
                  <li><b>Id: </b>{{selectedProduct.xarticulo_id}}</li>
                  <li><b>Nombre: </b>{{selectedProduct.xdescripcion}}</li>
                </ul>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onBtnCloseProductDialog">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import DateFind from "../../components/DateFind.vue";
import ArticuloArtProvFind from "../../components/ArtProv/ArticuloArtProvFind.vue";
import ProovedoresArtProvFind from "../../components/ArtProv/ProovedoresArtProvFind.vue";
import FamiliaArtProvFind from "../../components/ArtProv/FamiliaArtProvFind.vue";
import SubfamiliaArtProvFind from "../../components/ArtProv/SubfamiliaArtProvFind.vue";
//import FamiliasNoFinalizadas from "../../components/ArtProv/FamiliasNoFinalizadas.vue";
import { mapState } from "vuex";
export default ({
    components: {
        ArticuloArtProvFind, DateFind, ProovedoresArtProvFind, FamiliaArtProvFind, SubfamiliaArtProvFind//, FamiliasNoFinalizadas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz', 'dirId', 'perIdAux', 'esTiendaCliente']),
        isMartin: function () {
          return (this.empId == 8 || this.empId == 12 || this.empId == 15);
        },
        tableHeaders: function () {
          if (this.isMartin) {
            return this.martinHeaders.concat(this.actionHeaders);
          }
          return this.rinconHeaders.concat(this.actionHeaders);
        },
        familiasNoFinalizadas: function () {
          if (this.familias == null || this.pedido == null  || this.pedido.FamiliasFinalizadasList == null) { return []; }
          return this.familias.filter(f => !this.pedido.FamiliasFinalizadasList.includes(f))
        }
    },
    data () {
        return {
            loading: false,
            isNew: true,
            activePanels: 0,
            enviado: false,
            isValid: false,
            lineEditAction: 'NONE',
            CantidadPropuesta: 1,
            showProductDialog: false,
            showArtsDialog: false,
            showDeleteDialog: false,
            showDeleteLineaDialog: false,
            showErrorDialog: false,
            errorMsg: '',
            filtros: {
                Familia: null,
                Subfamilia: null
            },
            pedido: {
                PedidoId: null,
                FechaPedido: null,
                ProveedorObj: null,
                Observaciones: ''
            },
            filtrarCantidad: false,
            filtrarFamilias: true,
            filtroDia: false,
            apiLineas: [],
            apiLineasTotal: 0,
            dtOptionsLoaded: false,
            factores: [],
            familias: [],
            // lineasTotalDataRows: 0,
            lineasDataRows: [],
            lineasOptions: {itemsPerPage: 100},
            showModal: false,
            /*
            lineasHeaders: [
                { text: 'Artículo', width: 300, sortable: false, value: 'Articulo' },
                { text: '', width: 1, sortable: false, value: 'CodigoBarras', align: 'end' },
                { text: 'Stock', width: 200, sortable: false, value: 'Stock' },
                { text: 'Cantidad', width: 200, sortable: false, value: 'Cantidad' }
            ],
            */
            martinHeaders: [
                { text: 'Artículo', width: 300, sortable: false, value: 'Articulo' },
                // { text: '', width: 1, sortable: false, value: 'CodigoBarras', align: 'end' },
                { text: 'Cantidad', width: 150, sortable: false, value: 'Cantidad' },
                //{ text: 'Sugerencia', width: 150, sortable: false, value: 'CantidadPropuesta'},
                { text: 'Unidad por caja', width: 100, sortable: false, value: 'FactorCompra' },
                { text: 'Pedir por multiplo', width: 100, sortable: false, value: 'LoteMultiplo' }
            ],
            rinconHeaders:[
                { text: 'Artículo', width: 300, sortable: false, value: 'Articulo' },
                // { text: '', width: 1, sortable: false, value: 'CodigoBarras', align: 'end' },
                { text: 'Stock', width: 150, sortable: false, value: 'Stock' },
                { text: 'Sugerencia', width: 100, sortable: false, value: 'CantidadPropuesta'},
                { text: 'Cantidad', width: 150, sortable: false, value: 'Cantidad' },
                //{ text: 'Lote multiplo', width: 100, sortable: false, value: 'Pedir por multiplo' }

            ],
            actionHeaders: [
                { text: '', width: 50, sortable: false, value: 'Opt', align: 'end' }
            ],
            selectedProduct: null,
            selectedRow: null,
            editNewRow: false,
            newPedidoRow: {},
            selectedRowDelete: null
        }
    },
    watch: {
      lineasOptions: {
        handler () {
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadLineData();
        },
        deep: true
      },
      pedido: {
        handler () {
            this.validate();
        },
        deep: true,
      }
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
          this.pedido.PedidoId = this.$route.params.id;
          this.loadData();
      }
      else {
        var hoy = new Date();

        var day2Char = hoy.getDate().toString();
        var month2Char = (hoy.getMonth() + 1).toString();
        if (day2Char.length == 1) {day2Char = '0' + day2Char}
        if (month2Char.length == 1) {month2Char = '0' + month2Char}

        this.pedido.FechaPedido = hoy.getFullYear() + '-' + month2Char + '-' + day2Char;
        
        this.pedido.dias_festivos = 0;
        this.pedido.dias_post_calculo = 0;
        this.pedido.plazo_entrega = 0;
      }
    },
    methods: {
      goToList() {
        this.$router.push('/pedidos');
      },
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            PedidoId: this.pedido.PedidoId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Pedido;
            const proveedor = result.data.Proveedor;
            const factores = result.data.Factores;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item, proveedor, factores
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al cargar el pedido.');
                this.goToList();
            }
          });
        })
      },
      getLineDataFromApi() {
        this.loading = true;
        const NumRegsPag = this.lineasOptions.itemsPerPage;
        const NumPag = this.lineasOptions.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            PedidoId: this.pedido.PedidoId,
            Cantidad: this.filtrarCantidad,
            Familias: this.filtrarFamilias
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/get-lines", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.PedidosLineas;
            const total = result.data.TotalLineas;
            const factores = result.data.Factores;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  items, total, factores
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al cargar las lineas.');
            }
          });
        })
      },
      getFamiliasProveedorFromApi() {
        return new Promise((resolve) => {
          var controllerParameters = {
            EmpId: this.empId,
            DirId: this.dirId,
            Proveedor: this.pedido.ProveedorObj != null ? this.pedido.ProveedorObj.xproveedor_id : null,
            FamiliasExcluidas: [],
            NumRegsPag: 1000,
            NumPag: 1
        };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artprov/familia/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.EntsList;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                alert('Error al cargar las familias.');
            }
          });
        })
      },
      getProvDataFromApi() {
        return new Promise((resolve) => {
          var controllerParameters = {
            ProveedorId: this.pedido.ProveedorObj != null ? this.pedido.ProveedorObj.xproveedor_id : null,
            EmpId: this.empId,
            DirId: this.dirId,
            FechaPedido: this.pedido.FechaPedido
        };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artprov/provs/data", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                alert('Error al recuperar los datos del proveedor.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        let lines;

        switch(this.lineEditAction) {
          case 'NEW': 
            lines = this.newPedidoRow;
            break;
          case 'EDIT':
            lines = this.selectedRow;
            break;
          case 'DELETE':
            lines = this.selectedRowDelete;
            break;
          default:
            lines = null;
            break;
        }
        
        return new Promise((resolve) => {
          const controllerParameters = {
            EmpId: this.empId,
            DirId: this.dirId,
            PerId: this.esTiendaCliente ? this.perId : this.perIdAux,
            PedidoId: this.pedido.PedidoId,
            FechaPedido: this.pedido.FechaPedido,
            FechaEntrega: this.pedido.FechaEntrega,
            ProveedorId: this.pedido.ProveedorObj != null ? this.pedido.ProveedorObj.xproveedor_id : null,
            Proveedor: this.pedido.ProveedorObj != null ? this.pedido.ProveedorObj.xnomabrev : null,
            Observaciones: this.pedido.Observaciones,
            Festivos: this.pedido.dias_festivos,
            DiasConsumo: this.pedido.dias_post_calculo,
            Entrega: this.pedido.plazo_entrega,
            Lineas: lines,
            LineEditAction: this.lineEditAction
          };

          if (this.lineEditAction === 'ALL_ARTS')
          {
            controllerParameters.Familia = this.filtros.Familia != null ? this.filtros.Familia.familia : null;
            controllerParameters.Subfamilia = this.filtros.Subfamilia != null ? this.filtros.Subfamilia.subfamilia : null;
          }

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;
            // const item = result.data.Pedido;
            // const proveedor = result.data.Proveedor;
            // const factores = result.data.Factores;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al guardar el pedido.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            PedidoId: this.pedido.PedidoId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/delete", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  success
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al borrar el pedido.');
            }
          });
        })
      },
      sendFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
              EmpId: this.empId,
              DirId: this.dirId,
              PerId: this.esTiendaCliente ? this.perId : this.perIdAux,
              PedidoId: this.pedido.PedidoId,
              // Desarrollo: true
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/send", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
          const item = result.data;

          setTimeout(() => {
            this.loading = false
            resolve({
              item
            })
          }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                if (error.response.status === 400)
                {
                  alert(error.response.data.Message);
                  return;
                }
                alert('Error al enviar el pedido.');
            }
          });
        })
      },
      postFinishFamilyToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
              PedidoId: this.pedido.PedidoId,
              Familia: this.filtros.Familia != null ? this.filtros.Familia.familia : null
              // Desarrollo: true
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/finish-family", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
          const item = result.data;

          setTimeout(() => {
            this.loading = false
            resolve({
              item
            })
          }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al finalizar la familia.');
            }
          });
        })
      },
      //Formula
      /*postCantidadPropuesta() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            PedidoId: this.pedido.PedidoId,
            DirId: this.dirId,
            EmpId: this.empId,
            lines: this.selectedRow,
            FactorCompra: this.getFactor(this.selectedRow.Art.ArticuloId)
                   
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/formula", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {          
          const item = result.data;
          
          //this.selectedRow.CantidadPropuesta = item     
          //this.selectedRow.Cantidad = item

          setTimeout(() => {
            this.loading = false
            resolve({
              item
              
            })
          }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al calcular cantidad.');
            }
          });
        })
      }, 
      */     
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Pedido no encontrado.');
              this.goToList();
              return;
            }
            this.mapData(data);
            this.enviado = data.item.Enviado;
            this.loadFamilias();
          });
      },
      loadLineData() {
        this.getLineDataFromApi()
          .then(data => {
            if(data.items == null) {
              alert('Error al cargar las lineas.');
              return;
            }
            this.mapLineasNew(data);
          });
      },
      loadFamilias() {
        this.getFamiliasProveedorFromApi()
          .then(data => {
            if(data.item != null) {
              this.familias = data.item.map(m => m.familia);
            }
          });
      },
      loadProvData() {
        this.getProvDataFromApi()
          .then(data => {
            if(data.item != null) {
              this.pedido.dias_post_calculo = data.item.DiasConsumo;
              this.pedido.plazo_entrega = data.item.Entrega;
              this.$forceUpdate();
            }
          });
      },
      saveData() {
        this.postDataToApi().then(data => {
          if(data.item.PedidoId != null) {
              this.pedido.PedidoId = data.item.PedidoId;
              if(this.lineEditAction == 'NONE') {
                this.goToList();
                return;
              } else {
                let index = -1;
                switch(this.lineEditAction) {
                  // case 'NEW':
                  //   // añadir linea
                  //   this.apiLineas.push({
                  //     PedidoId: data.item.PedidoId,
                  //     Linea: data.item.Linea.Linea,
                  //     ArticuloId: data.item.Linea.ArticuloId,
                  //     Articulo: data.item.Linea.Articulo,
                  //     Cantidad: data.item.Linea.Cantidad,
                  //     CantidadPropuesta: data.item.Linea.CantidadPropuesta,
                  //     Stock: data.item.Linea.Stock
                  //   });
                  //   this.editNewRow = false;
                  //   break;
                  case 'DELETE':
                    // buscar linea - borrar linea
                    // this.apiLineas = this.apiLineas.filter(f => f.Linea != data.item.Linea.Linea)
                    this.showDeleteLineaDialog = false;
                    break;
                  case 'EDIT':
                    // buscar linea - actualizar linea
                    // index = this.apiLineas.findIndex(f => f.Linea == data.item.Linea.Linea);
                    // this.apiLineas.splice(index, 1);
                    // this.apiLineas.splice(index, 0, {
                    index = this.lineasDataRows.findIndex(f => f.Linea == data.item.Linea.Linea);
                    this.lineasDataRows.splice(index, 1);
                    this.lineasDataRows.splice(index, 0, {
                      PedidoId: data.item.PedidoId,
                      Linea: data.item.Linea.Linea,
                      ArticuloId: data.item.Linea.ArticuloId,
                      Articulo: data.item.Linea.Articulo,
                      Cantidad: data.item.Linea.Cantidad,
                      CantidadPropuesta: data.item.Linea.CantidadPropuesta,
                      Stock: data.item.Linea.Stock
                    });
                    break;
                  // case 'ALL_ARTS':
                  //   this.loadData();
                  //   break;
                }
                if(this.pedido.PedidoId == null)
                {
                  this.loadFamilias();
                }
                // if(this.lineEditAction != 'ALL_ARTS')this.mapLineas();

                if (this.lineEditAction != 'EDIT')
                {
                  this.loadLineData();
                }
              }
          }
          else {
              this.errorMsg = data.item.Error;
              this.showErrorDialog = true;
          }
          
          this.lineEditAction = 'NONE';
        });
      },
      mapData(data) {
        this.pedido = data.item;
        // this.factores = data.factores;
        const date = new Date(data.item.FechaPedido);
        this.pedido.FechaPedido = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')  + '-' + String(date.getDate()).padStart(2, '0');
        if(data.item.FechaEntrega != null)
        {
          const dateEntrega = new Date(data.item.FechaEntrega);
          this.pedido.FechaEntrega = dateEntrega.getFullYear() + '-' + String(dateEntrega.getMonth() + 1).padStart(2, '0')  + '-' + String(dateEntrega.getDate()).padStart(2, '0');
        }
        this.pedido.ProveedorObj = {
            xproveedor_id: data.item.ProveedorId,
            xnomabrev: data.item.Proveedor,
            FechaEntregaVisible: data.proveedor.FechaEntregaVisible 
        }

        // this.lineasDataRows = [];
        // this.apiLineas = [];
        // data.item.PedidosLineas.forEach(i => {
        //   this.apiLineas.push({
        //     PedidoId: i.PedidoId,
        //     Linea: i.Linea,
        //     ArticuloId: i.ArticuloId,
        //     Articulo: i.Articulo,
        //     Cantidad: i.Cantidad,
        //     CantidadPropuesta: i.CantidadPropuesta,
        //     Stock: i.Stock
        //   });
        // });
        // this.mapLineas();
        this.loadLineData();
      },
      mapLineas() {
        // Cancelamos la edición si se está editando alguna linea
        if(this.selectedRow != null) {
          this.apiLineas
          .filter(f => f.edit).forEach(i => {
            i.edit = false;
          });
          this.selectedRow = null;
        }

        // this.apiLineas.sort((a, b) => {
        //   if (a.Articulo < b.Articulo) {
        //     return -1;
        //   }
        //   if (a.Articulo > b.Articulo) {
        //     return 1;
        //   }
        //   return 0;
        // });

        if(this.filtrarCantidad) {
          this.lineasDataRows = this.apiLineas.filter(f => f.Cantidad != 0);
        } else {
          this.lineasDataRows = this.apiLineas;
        }

        if (this.filtrarFamilias)
        {
          this.lineasDataRows = this.lineasDataRows.filter(f => {
            const familia = this.getFamilia(f.ArticuloId);
            return !this.pedido.FamiliasFinalizadasList.includes(familia);
          });
        }

      },
      mapLineasNew(data) {
        this.factores = data.factores;
        this.selectedRow = null;
        this.apiLineas = [];
        this.lineasDataRows = [];
        data.items.forEach(i => {
          this.lineasDataRows.push({
            PedidoId: i.PedidoId,
            Linea: i.Linea,
            ArticuloId: i.ArticuloId,
            Articulo: i.Articulo,
            Cantidad: i.Cantidad,
            CantidadPropuesta: i.CantidadPropuesta,
            Stock: i.Stock
          });
        });
        this.apiLineasTotal = data.total;
      },
      getFactor(item) {
        try {
          const factor = this.factores.find(f => f.xarticulo_id == item);
          return factor.FactorCompra;
        } catch {
          return '';
        }
      },
      getFamilia(item) {
        try {
          const factor = this.factores.find(f => f.xarticulo_id == item);
          return factor.familia;
        } catch {
          return '';
        }
      },
      getUnidad(item) {
        try {
          const factor = this.factores.find(f => f.xarticulo_id == item);
          return factor.unidadPrincipal;
        } catch {
          return '';
        }
      },
      getMultiplo(item) {
        try {
          const factor = this.factores.find(f => f.xarticulo_id == item);
          return factor.loteMultiplo;
        } catch {
          return '';
        }
      },
      checkMultiplo(value, multiplo) {
        return value%multiplo == 0;
      },
      // Crear o editar una linea del pedido
      onClickRowEditarLinea(item) {
        if(this.loading || item.edit || this.enviado) { return; }
        
        if(this.selectedRow != null) {
          this.onBtnSaveEditLinea();
          return;
          // this.lineasDataRows
          // .filter(f => f.edit).forEach(i => {
          //   i.edit = false;
          // });
        }
        
        item.edit = true;

        this.selectedRow = {
          PedidoId: item.PedidoId,
          Linea: item.Linea,
          Cantidad: item.Cantidad,
          CantidadPropuesta: item.CantidadPropuesta,
          Stock: item.Stock,
          Art: {
            xarticulo_id: item.ArticuloId,
            xdescripcion: item.Articulo,
            FactorCompra: this.getFactor(item.ArticuloId),
            UnidadPrincipal: this.getUnidad(item.ArticuloId),
            LoteMultiplo: this.getMultiplo(item.ArticuloId)
          }
        };
        this.$forceUpdate();
      },
      onBtnNuevaLinea() {
        if(this.enviado) { return; }
        this.editNewRow = true;
        this.newPedidoRow = {};
      },
      // Confirmación guardado
      onBtnSave() {
          if(this.enviado) { return; }
          this.validate();
          if(!this.isValid) { return; }
          this.saveData();
      },
      onBtnSaveLinea() {
          if(this.enviado) { return; }
          this.newPedidoRow.ArticuloId = this.newPedidoRow.Art != null ? this.newPedidoRow.Art.xarticulo_id : 0;
          this.newPedidoRow.Articulo =  this.newPedidoRow.Art != null ?  this.newPedidoRow.Art.xdescripcion : null;
          this.lineEditAction = 'NEW';
          this.saveData();
      },
      onBtnSaveEditLinea () {
        if(this.enviado) { return; }
        this.lineEditAction = 'EDIT';
        this.selectedRow.ArticuloId =  this.selectedRow.Art != null ? this.selectedRow.Art.xarticulo_id : 0;
        this.selectedRow.Articulo = this.selectedRow.Art != null ? this.selectedRow.Art.xdescripcion : null;
        //this.onBtnSugerir();
        this.saveData();
      },
      onBtnAllArt () {
        if(this.enviado) { return; }
        this.lineEditAction = 'ALL_ARTS';
        this.saveData();
      },
      onBtnAddArts (){

      },
      /*
      onBtnSugerir () {
        if(this.enviado) {return; }
        this.postCantidadPropuesta()
        .then(data => {
          if(data.item) { 
            this.selectedRow.CantidadPropuesta = data.item;
            this.selectedRow.Cantidad = data.item;
          }
        });
      },
      */
      confirmarEnviar(){
        for (const familia of this.pedido.FamiliasFinalizadasList) {
          console.log(familia);
        }
       
        this.openModal()
      },
      // Enviar pedido
      onBtnEnviar() {
        this.closeModal()
        if(this.enviado) { return; }
        this.sendFromApi()
        .then(data => {
     
          if(data.item.Error != null) { 
            this.errorMsg = data.item.Error;
            this.showErrorDialog = true;
          }
          else if(!data.item) { 
            this.errorMsg = 'Pedido no enviado';
            this.showErrorDialog = true;
          }
          else {
            // Marcar como enviado
            this.enviado = true;
            this.goToList();
          }
        });
      },
      // Finalizar familia
      onBtnFinalizarFamilia() {
        if(this.enviado) { return; }
        this.postFinishFamilyToApi()
        .then(data => {
          if(data.item) { 
            this.pedido.FamiliasFinalizadasList = data.item;
            this.mapLineas();
          }
        });
      },
      // Confirmación de borrado
      onBtnDelete() {
        if(this.enviado) { return; }
        this.deleteDataFromApi().then(data => {
          this.showDeleteDialog = false;
          if (data.success.Success) {
            this.goToList();
          } else {
              this.errorMsg = data.success.Error;
              this.showErrorDialog = true;
          }
        });
      },
      onBtnLineaDelete() {
        if(this.enviado) { return; }
        this.lineEditAction = 'DELETE';
        this.saveData();
      },
      // Cancelar edición de lineas
      onBtnCancelLinea() {
        this.editNewRow = false;
      },
      onBtnCancelEditLinea() {
        this.lineasDataRows
        .filter(f => f.edit).forEach(i => {
          i.edit = false;
        });
        this.selectedRow = null;
        this.$forceUpdate();
      },
      // Mostrar modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowLineaDeleteModal(item) {
        this.selectedRowDelete = item;
        this.showDeleteLineaDialog = true;
      },
      onBtnShowProductDialog(item) {
        if (item == null) return;
        this.selectedProduct = item;
        this.showProductDialog = true;
      },
      // Cierre modales
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelLineaDelete() {
        this.selectedRowDelete = null;
        this.showDeleteLineaDialog = false;
      },
      onBtnCloseError() {
        this.errorMsg = '',
        this.showErrorDialog = false;
      },
      onBtnCloseProductDialog() {
        this.showProductDialog = false;
        this.selectedProduct = null;
      },
      onBtnCloseArts(){
        this.showArtsDialog = false;
      },
      // Eventos y validaciones
      changeProveedor() {
        this.pedido.FechaEntrega = null;
        this.loadProvData();
      },
      onBlurNew() {
        if( this.selectedRow.Art.LoteMultiplo != null){
          if (this.newPedidoRow.Art != null && this.checkMultiplo(this.newPedidoRow.Cantidad, this.newPedidoRow.Art.loteMultiplo))
          {
            this.onBlurFixCantidad();
          }
          else {
            alert('Debes seleccionar un valor de Cantidad que sea un multiplo correcto')
            this.newPedidoRow.Cantidad = 0
          }
        }
      },
      onBlurEdit() {
        if( this.selectedRow.Art.LoteMultiplo != null){
      
          if (this.selectedRow.Art != null && this.checkMultiplo(this.selectedRow.Cantidad, this.selectedRow.Art.LoteMultiplo))
          {
            this.onBlurFixCantidad();
          }
          else {
            alert('Debes seleccionar un valor de Cantidad que sea un multiplo correcto')
            this.selectedRow.Cantidad = 0
          }
        }
      },
      onBlurFixCantidad() {
          if(this.newPedidoRow != null) { this.newPedidoRow.Cantidad = Number(this.newPedidoRow.Cantidad).toFixed(2); }
          if(this.selectedRow != null) { this.selectedRow.Cantidad = Number(this.selectedRow.Cantidad).toFixed(2); }
      },
      onBlurFixCantidadPropuesta() {
          if(this.newPedidoRow != null) { this.newPedidoRow.CantidadPropuesta = Number(this.newPedidoRow.CantidadPropuesta).toFixed(2); }
          if(this.selectedRow != null) { this.selectedRow.CantidadPropuesta = Number(this.selectedRow.CantidadPropuesta).toFixed(2); }
      },
      changeCantidad(item, factor = 1, isNew = false, forceUpdate = false)
      {
        const itemNumber = isNaN(item) ? 0 : Number(item);
        const result = itemNumber + factor;
        if(isNew) this.newPedidoRow.Cantidad = result;
        else this.selectedRow.Cantidad = result;
        this.onBlurFixCantidad(); 
        if (forceUpdate) this.$forceUpdate();
      },
      onBlurFixStock() {
          if(this.newPedidoRow != null) { this.newPedidoRow.Stock = Number(this.newPedidoRow.Stock).toFixed(2); }
          if(this.selectedRow != null) { this.selectedRow.Stock = Number(this.selectedRow.Stock).toFixed(2); }
      },
      changeStock(item, factor = 1, isNew = false, forceUpdate = false)
      {
        const itemNumber = isNaN(item) ? 0 : Number(item);
        const result = itemNumber + factor;
        if(isNew) this.newPedidoRow.Stock = result;
        else this.selectedRow.Stock = result;
        this.onBlurFixStock(); 
        if (forceUpdate) this.$forceUpdate();
      },
      validate() {
          if (this.pedido == null) {
              this.isValid = false;
              return;
          }
          this.isValid = this.pedido.FechaPedido != null && this.pedido.ProveedorObj != null;
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      closeModal() {
        this.showModal = false;
      },
      openModal() {
        this.showModal = true;
      } 
    }
})
</script>

<style>
/* Chrome, Safari, Edge, Opera */
.no-arrows>div>div>div>input::-webkit-outer-spin-button,
.no-arrows>div>div>div>input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
.no-arrows>div>div>div>input[type=number] {
-moz-appearance: textfield;
appearance: textfield;
}
</style>
