<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :disabled="readonly"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="fechaFormateada"
        :label="label"
        @blur="value = parseDate(fechaFormateada)"
        v-on="on"
        outlined
        dense
        append-icon="mdi-calendar"
        class="mr-1"
        readonly
        ref="CalendarActivator"
        :clearable="clearable && !readonly"
        @click:clear="clearEvt"
        @click:append="clickAppendEvt"
        @change="changeEvt"
      ></v-text-field>
    </template>
    <v-date-picker v-model="value" :readonly="readonly" no-title first-day-of-week="1" @input="inputEvt" @change="changePickerEvt"></v-date-picker>

    <!-- <v-date-picker v-model="value" no-title first-day-of-week = "1" @input="menu1 = false"></v-date-picker> -->
  </v-menu>
</template>

<script>
  export default {
    data () {
      return {
        menu1: false,
        fechaFormateada: '' // this.formatDate(new Date().toISOString().substr(0, 10)),
      }
    },
    props: ['value', 'label', 'clearable', 'readonly'],
    watch: {
      value () {
        this.fechaFormateada = this.formatDate(this.value);
      },
    },
    methods: {
      clickEvt() {
        this.$emit('click');
      },
      clickAppendEvt() {
        this.toogleMenu();
        if (this.menu1) {
          this.$refs.CalendarActivator.focus();
        }
        this.$emit('click:append');
      },
      inputEvt() {
          this.menu1 = false;
          this.$emit('input', this.value);
        },
      formatDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      },
      parseDate (date) {
        if (!date) return null;

        const [day, month, year] = date.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      },
      toogleMenu () {
        this.menu1 = !this.menu1;
      },
      clearEvt() {
        this.value = '';
        this.fechaFormateada = '';
          this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      changePickerEvt() {
        this.$emit('change', this.value);
      }
    },
    mounted() {
      if (this.value != null && this.value != '') {
        this.fechaFormateada = this.formatDate(this.value);
      }
    },
  }
</script>
